
import { defineComponent, onMounted } from "vue";

import ListWidget5 from "@/components/widgets/lists/Widget5.vue"; 
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue"; 
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import AccountList from "@/components/dashboard/AccountList.vue"
import AccountDetails from "@/components/dashboard/AccountDetails.vue"
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue"
import {transactionhistory}  from "@/api/transaction";

import  SealProEncode from "@/views/modals/SealProEncode.vue"
import { getCurrentUser } from "@/utils/index"
import { getDashboardData } from "@/api/dashboard"
import { UserRole } from "../../utils/auth.roles";
import  AdvisoryBanner from "@/components/dashboard/AdvisoryBanner.vue"
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue"
// import UgandaPromo from "../UgandaPromo.vue";

export default defineComponent({
  name: "dashboarddtc",
  components: {
    ListWidget5,
    MixedWidget2,
    SealProEncode,
    AccountList,
    AccountDetails,
    // CompanyPolicy,
    AdvisoryBanner,
    // ECShopAdvisory,
    // UgandaPromo
},
  data() {
    return {
      user: {},
      dashboarddata: {},
      allowClose: true,
      inputActivationDetail: false,
      isAddAccount: false,
      componentKey: 0,
      allowChangeName: true,
      encode_now_sealpro_key: 0,
      oviRankImage: "",
      firstCoverage: {},
      rankPrimeImage : "",
      showRankImage : true,
      currency : "",
      echequeUrl:"",
      pt : "",
      ccode : 0
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  }, 
  methods: {
    async getDashboardData() {
      const response = await getDashboardData();
      console.log(response.data);
        if(response.data !== undefined ) {
          this.currency = response.data.currency;
          this.echequeUrl = response.data.echequeUrl;


          this.ccode = response.data.countryCode;
          if(this.currency == "PHP"){
            //this.showAdvisoryModal();
          }
        }
    },
    async transactionhistory(){ 
        const response = await transactionhistory(1);

      },
      
    addAccount() {
        this.allowClose = true; 
        this.inputActivationDetail;
        this.isAddAccount = true;
        this.allowChangeName = false;

        setTimeout(() => {   
          const btn:any = this.$refs.addAccountModalButton;
          btn.click();
        }, 500);
        
    },
    encodeSealProNow(allowChangeName) { 
        
        this.allowChangeName = allowChangeName;
        this.encode_now_sealpro_key += 1; //this will force reload the component
        setTimeout(() => {   
          const btn:any = this.$refs.encodeNowSealproModalButton;
          btn.click();
        }, 500);
    },

    showAdvisoryModal() {
      setTimeout(() => {   
        const btn:any = this.$refs.advisoryModalButton;
        btn.click();
      }, 500);
    },

    



  },
  mounted(){
    
        this.getDashboardData();
        this.user = getCurrentUser();
        this.pt = String(localStorage.getItem("pt"));


        if(localStorage.getItem("pt") === 'ovi'){
          this.$router.push('/dashboard/reminderspage') ;
        } else if(localStorage.getItem("pt") === 'bibo' ){
            this.$router.push('/dashboardbibo') ;
        }

  },
});
